import Link from "next/link";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../styles/ShopByPrice.module.css";
function ShopByPrice() {
  const handleClick = (value: number) => {};

  const createParams = (from: any, to: any, page: number) => {
    let paramss = new URLSearchParams();
    paramss.append("priceFrom", `${from}`);
    paramss.append("priceTo", `${to}`);
    paramss.append("page", `${page}`);

    return `${paramss}`;
  };
  return (
    <div className="main mb-5  ">
      <h1 className={`text-center fs-24 ${styles.heading} `}>Budget Deals</h1>

      <Row className={`text-center mx-2  ${styles.shopbyprice}`}>
        <Col
          lg="12"
          className="d-lg-flex d-md-flex d-sm-flex d-grid gap-2 pe-0 justify-content-center mb-2 "
        >
          <div className="d-flex gap-2 w-100">
            <Link
              href={`/products?${createParams(0, 5000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3 "
            >
              {" "}
              Under Rs 5000{" "}
            </Link>
            <Link
              href={`/products?${createParams(5000, 10000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              {" "}
              Rs 5000 - Rs 10000{" "}
            </Link>
          </div>
          <div className="d-flex gap-2 w-100">
            <Link
              href={`/products?${createParams(10000, 15000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3 "
            >
              {" "}
              Rs 10000 - Rs 15000{" "}
            </Link>
            <Link
              href={`/products?${createParams(15000, 20000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              {" "}
              Rs 15000 - Rs 20000{" "}
            </Link>
          </div>
        </Col>
        <Col
          lg="12"
          className="d-lg-flex d-md-flex d-sm-flex d-grid pe-0 gap-2 justify-content-center "
        >
          <div className="d-flex gap-2  w-100">
            <Link
              href={`/products?${createParams(20000, 25000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              {" "}
              Rs 20000- Rs 25000{" "}
            </Link>
            <Link
              href={`/products?${createParams(25000, 30000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              Rs 25000- Rs 30000{" "}
            </Link>
          </div>
          <div className="d-flex gap-2 w-100">
            <Link
              href={`/products?${createParams(30000, 40000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              {" "}
              Rs 30000 - Rs 40000{" "}
            </Link>
            <Link
              href={`/products?${createParams(40000, 50000, 1)}`}
              className="w-100 bg-secondary align-items-center d-flex justify-content-center p-2 rounded-3"
            >
              {" "}
              Rs 40000 - Rs 50000{" "}
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ShopByPrice;
