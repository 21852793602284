"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { tblogo, tblogoName } from "../../public/images";
import styles from "../styles/SignInModal.module.css";
import useAuth from "./auth/useAuth";
import Swal from "sweetalert2";
import ButtonLoader from "./ButtonLoader";
import ReactInputMask from "react-input-mask";
import { InfoIcon } from "../../public/icons";
import { sendGTMEvent } from "@next/third-parties/google";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

type responseFlag = {
  success: String;
  registeredUser: boolean;
};

function SignInModal(props: any) {
  const { useGoogleLogin, navigate, fetchGoogleUserData, manualLoginMutation, LoginThroughOTPMutation, signupMutation, VerifyOTP } = useAuth();
  const [mode, setMode] = useState("login");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<boolean | string | null | undefined>();
  // const [number, setNumber] = useState<string>("");
  const pathname = usePathname();
  const [loginMethod, setLoginMethod] = useState("OTP");
  const [loading, setLoading] = useState<Boolean>(false);
  const [responseFlag, setResponseFlag] = useState<responseFlag>({
    success: "",
    registeredUser: true,
  });
  const googleLogin = useGoogleLogin(() => {
    // DO TO AFTER LOGIN

    setMode("login");
  });

  const initialLoginValues = {
    email: "",
    password: "",
    number: "",
    first_name: "",
    last_name: "",
  };
  useEffect(() => { }, [mode]);

  const initialSignupValues = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    auth_type: "m",
  };

  const [intialvalues, setintialvalues] = useState<any>(mode === "login" ? initialLoginValues : initialSignupValues);

  const [validated, setValidated] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputsRef = useRef<any>([]);
  const [seconds, setSeconds] = useState(60); // Initial time for countdown
  const [isDisabled, setIsDisabled] = useState(true); // Disable resend button initially

  useEffect(() => {
    let timer: any;
    if (isDisabled && seconds > 0) {
      // Timer runs while the resend button is disabled and seconds > 0
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      // Enable resend button when countdown ends 
      setIsDisabled(false);
    }

    // Cleanup the interval on unmount
    return () => clearInterval(timer);
  }, [seconds, isDisabled]);
  const handleOTPChange = (e: any, index: number) => {
    const value = e.target.value;

    // Only accept numeric values and move focus to next input
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if there's one
      if (index < otp.length - 1) {
        inputsRef?.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === "Backspace") {
      // If input is empty, move focus to previous input
      if (otp[index] === "") {
        if (index > 0) {
          inputsRef.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const handlePaste = (e: any) => {
    const pasteData = e.clipboardData.getData("text").split("");
    if (pasteData.length === 4 && pasteData.every((char: string) => /^\d$/.test(char))) {
      setOtp(pasteData);
      pasteData.forEach((digit: number | string, index: number) => {
        inputsRef.current[index].value = digit;
      });
      inputsRef.current[3].focus();
    }
  };
  const handleChange = (e: any) => {
    if (mode === "login") {
      setintialvalues({ ...intialvalues, [e.target.name]: e.target.value });
    } else if (mode === "signup") {
      if (e.target.name === "first_name" || e.target.name === "last_name") {
        let isValidInput = /^[a-zA-Z\s]*$/.test(e.target.value);
        if (isValidInput) {
          setintialvalues({ ...intialvalues, [e.target.name]: e.target.value });
        }
      } else {
        setintialvalues({ ...intialvalues, [e.target.name]: e.target.value });
        signupMutation.reset();
      }
    }
  };



  const ResendEmail = () => {

    if (loginMethod === "OTP" && intialvalues?.number !== "") {
      LoginThroughOTPMutation.mutate({ phone_number: intialvalues.number }, {
        onSuccess: (data) => {
          // Handle successful Number
          setSeconds(60);
          setResponseFlag(data.data.body.data);
          setLoading(false);
          setValidated(false);
        },

        onError: (error) => {
          // Handle error
          setLoading(false);

          setOtp(["", "", "", ""]);
        },
      });
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setValidated(true);
    if (mode === "login") {
      // Handle login
      let UserInfo = {
        ...(responseFlag?.registeredUser === false && {
          first_name: intialvalues.first_name,
          last_name: intialvalues.last_name,
        }),
        otp: Number(otp.join("").replaceAll(",", "")),
        phone_number: intialvalues.number,
      };
      if (loginMethod === "OTP" && responseFlag.success === "" && UserInfo?.phone_number !== "") {
        setLoading(true);
        LoginThroughOTPMutation.mutate(UserInfo, {
          onSuccess: (data) => {
            // Handle successful Number
            setSeconds(60);
            setResponseFlag(data.data.body.data);
            setLoading(false);
            setValidated(false);
          },

          onError: (error) => {
            // Handle error
            setLoading(false);
            setOtp(["", "", "", ""]);
          },
        });
      } else if (loginMethod === "OTP" && responseFlag.success !== "") {
        if (responseFlag?.registeredUser === false ? UserInfo?.first_name && UserInfo?.last_name : true) {
          setLoading(true);
          VerifyOTP.mutate(UserInfo, {
            onSuccess: (data) => {
              // Handle successful Number
              setResponseFlag(data.data.body.data);
              setLoading(false);
              props.onHide();
              setValidated(false);
            },

            onError: (error) => {
              // Handle error
              setLoading(false);
              setError(error?.response?.data.body.message);

              setOtp(["", "", "", ""]);
            },

          });

        }
      } else {
        if (intialvalues.email !== "" && intialvalues.password !== "") {
          setLoading(true);
          manualLoginMutation.mutate(intialvalues, {
            onSuccess: () => {
              props.onHide();
              setLoading(false);
              setMode("login");
            },
            onError: (error) => {
              setLoading(false);
              setError(true);
            },
          });
        }
        // setLoading(false);
      }
    } else if (mode === "signup") {
      // Handle signup
      const obj = {
        first_name: intialvalues.first_name,
        last_name: intialvalues.last_name,
        phone_number: intialvalues.phone_number,
        email: intialvalues.email,
        password: intialvalues.password,
        url: "https://github.com/vercel/next.js/discussions/48110",
      };

      if (!Object.values(obj).includes("") && intialvalues.password === confirmPassword && !intialvalues.phone_number.includes("_") && intialvalues?.first_name?.length >= 3 && intialvalues?.last_name?.length >= 3) {
        setLoading(true);
        signupMutation.mutate(obj, {
          onSuccess: (data) => {
            // Handle successful signup

            props.onHide();
            setLoading(false);
            setMode("login");
          },
          onError: (error: any) => {
            // setError(error.response.data.message[0].message.isPhoneNumber=='phone_number must be a valid phone number'? 'Enter a Valid Phone Number':'')

            // switch (error.response.data.status) {
            //   case 409:
            //     error.response.data.message = error.response.data.body.message;
            //     break;
            //   case 500:
            //     error.response.data.message = error.response.data.body.message;
            // }
            if (error?.response?.data?.body) {
              setError(error?.response?.data.body.message);
            } else if (error?.response.data.message) {
              setError(error.response.data.message[0].message.isPhoneNumber == "phone_number must be a valid phone number" ? "Enter a Valid Phone Number" : "");
            }
          },
        });
      }
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={`d-grid  ${styles.modal}`}>
        <span
          onClick={() => {
            props.onHide();
            setMode("login");
            setValidated(false);
            setOtp(["", "", "", ""]);
            setLoading(false);
            setLoginMethod("OTP");
            setResponseFlag({ success: "", registeredUser: true });
            setError("");
            setintialvalues({ ...intialvalues, number: "" });
          }}
          className={`${styles.cross} cursor-pointer`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" role="img" className="icon icon-24 lazyloaded">
            <rect x="3" y="5" width="18" height="16" fill="white"></rect>
            <path
              fill-rule="evenodd"
              clipRule="evenodd"
              d="M12.5051 0.135254C14.9528 0.135152 17.3455 0.860879 19.3807 2.22066C21.416 3.58044 23.0022 5.51321 23.939 7.77453C24.8757 10.0359 25.1209 12.5242 24.6434 14.9248C24.166 17.3255 22.9873 19.5306 21.2566 21.2614C19.5259 22.9922 17.3207 24.1709 14.9201 24.6485C12.5195 25.126 10.0311 24.881 7.76979 23.9443C5.50843 23.0076 3.57561 21.4214 2.21576 19.3862C0.855913 17.3511 0.130107 14.9583 0.130127 12.5107C0.130141 9.22858 1.43392 6.08092 3.75467 3.76009C6.07542 1.43926 9.22304 0.135377 12.5051 0.135254V0.135254ZM7.13525 16.4156C6.9463 16.6101 6.84148 16.8711 6.84346 17.1423C6.84544 17.4135 6.95406 17.6729 7.14583 17.8647C7.3376 18.0564 7.59711 18.1649 7.86827 18.1668C8.13943 18.1687 8.40044 18.0638 8.59488 17.8748L12.4997 13.9699L16.4103 17.8805C16.6047 18.0698 16.8659 18.1749 17.1372 18.1731C17.4085 18.1712 17.6682 18.0626 17.8601 17.8707C18.0519 17.6788 18.1605 17.4191 18.1623 17.1478C18.164 16.8765 18.0589 16.6153 17.8695 16.421L13.9592 12.5106L17.8847 8.58516C18.072 8.39039 18.1754 8.12994 18.1727 7.85976C18.1701 7.58958 18.0616 7.33122 17.8705 7.14017C17.6795 6.94911 17.4211 6.8406 17.1509 6.83794C16.8808 6.83529 16.6203 6.93869 16.4255 7.12594L12.4997 11.051L8.57998 7.13125C8.38644 6.93769 8.12393 6.82894 7.8502 6.82892C7.57647 6.82891 7.31394 6.93763 7.12038 7.13118C6.92681 7.32472 6.81806 7.58724 6.81804 7.86097C6.81803 8.13469 6.92675 8.39722 7.1203 8.59078L11.0402 12.5106L7.13525 16.4156Z"
              fill="#979797"
            ></path>
          </svg>
        </span>
        <Row className="p-0 ">
          <Col md={0} lg={5} sm={0} className={` h-100 bg-light  d-none justify-content-center d-lg-grid d-sm-none d-md-none align-items-center ${styles.logoCol}`}>
            <div className="justify-content-center  d-grid ">
              <div className="text-center">
                <Image alt="banner" className="text-center" src={tblogo} width={50} height={50} />
              </div>
              <Image alt="banner" className="mb-0" src={tblogoName} width={150} height={30} />
            </div>
          </Col>

          <Col md={12} lg={7} sm={12} className={` h-100 justify-content-center d-grid align-items-center ${styles.SignDiv}`}>
            <h1 className={`${styles.bannerText} d-flex justify-content-start`}>{mode === "login" ? "Already a TechBazaar User" : "Signup"}</h1>
            {/* <h2 className={`${styles.loginMethod} d-flex justify-content-start`}>{loginMethod === "OTP" ? "Login via OTP" : "Login via Email"}</h2> */}

            {mode !== "login" && error && (
              <div className="row align-items-center justify-content-center">
                <div className="alert alert-warning w-75 mt-0 mb-0 py-1" role="alert ">
                  <p className="text-danger text-center mb-0 ">{error}</p>
                </div>
              </div>
            )}

            {mode === "login" ? (
              <Form noValidate validated={validated} onSubmit={handleSubmit} id="SignIn">
                <Row className={`mt-3 justify-content-center `}>
                  {loginMethod !== "OTP" ? (
                    <>
                      <Col lg={11} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                        <Form.Control onChange={handleChange} required name="email" type={"email"} value={intialvalues.email} className="border-dark" placeholder="Email" />
                      </Col>
                      <Col lg={11} className={`pe-0 mt-3 text-center d-flex justify-content-center  ${styles.cities}`}>
                        <Form.Control onChange={handleChange} name="password" type="password" required value={intialvalues.password} className="border-dark" placeholder="Password" />
                      </Col>
                    </>
                  ) : (
                    <>
                      {responseFlag.success !== "" ? (
                        <>
                          <Col lg={11} className={`pe-0 mt-3 text-center d-flex flex-column justify-content-center  ${styles.cities}`}>
                            <Form.Label className="">OTP</Form.Label>
                            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                              {otp.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  value={otp[index]}
                                  onChange={(e) => handleOTPChange(e, index)}
                                  onKeyDown={(e) => handleKeyDown(e, index)}
                                  onPaste={handlePaste}
                                  ref={(el) => (inputsRef.current[index] = el)}
                                  maxLength={1}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    fontSize: "24px",
                                    textAlign: "center",
                                  }}
                                />
                              ))}
                            </div>
                            {responseFlag.success !== "" && <p className="flex mt-1 text-center "> {seconds}s remaining <button disabled={isDisabled} className="link cursor-pointer " type="button" onClick={ResendEmail} > Resend OTP </button></p>}
                          </Col>
                          {responseFlag?.registeredUser === false && (
                            <>
                              {" "}
                              <Col lg={11} className={`pe-0  text-center d-flex flex-column justify-content-center ${styles.cities}`}>
                                {/* <Form.Label className="text-start">First Name</Form.Label> */}
                                <Form.Control onChange={handleChange} required name="first_name" type={"text"} value={intialvalues.first_name} className="border-dark w-100 w-75" placeholder="Enter your first name" />
                                <Form.Control.Feedback type="invalid"> Please Enter first name</Form.Control.Feedback>

                              </Col>
                              <Col lg={11} className={`pe-0 mt-3 text-center d-flex flex-column justify-content-center ${styles.cities}`}>
                                {/* <Form.Label className="text-start">Last Name</Form.Label> */}
                                <Form.Control onChange={handleChange} required name="last_name" type={"text"} value={intialvalues.last_name} className="border-dark w-100 w-75" placeholder="Enter your last name" />
                                <Form.Control.Feedback type="invalid"> Please Enter last name</Form.Control.Feedback>

                              </Col>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Col lg={11} className={`pe-0 mt-3  d-flex flex-column ${styles.cities}`}>
                            <Form.Group className="">

                              <Form.Label className="text-start">Phone Number</Form.Label>
                              <InputMask className="w-100" mask="+\92-399-9999999" value={intialvalues.number} name="number" onChange={handleChange} required>
                                <Form.Control isValid={validated && intialvalues?.number.replace(/\D/g, "").length === 12} isInvalid={validated && intialvalues?.number.replace(/\D/g, "").length < 12} minLength={12} className="border-dark w-75" placeholder="+92-3XX-XXXXXXX" type="tel" required={true} />
                              </InputMask>
                              <Form.Control.Feedback type="invalid">* Please Enter Valid Phone Number</Form.Control.Feedback>
                            </Form.Group>

                          </Col>
                        </>
                      )}
                    </>
                  )}
                  {error && <p className="mt-1 text-danger text-center ms-2 ">{manualLoginMutation.error?.response?.data?.body?.message}</p>}
                  {error && <p className="m-0 text-danger text-center ms-2 ">{error}</p>}

                  <Col lg={11} className={`${styles.ctaButton} text-center pe-0 mt-3 text-center d-flex flex-column justify-content-center  mt-2`}>
                    <Button type="submit" className="w-100   mt-2">
                      {loading ? <ButtonLoader /> : loginMethod === "OTP" && responseFlag.success === "" ? "Send OTP" : "Login"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit} id="SignUp">
                <Row className={`mt-3 justify-content-center  `}>
                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <Form.Control onChange={handleChange} minLength={3} maxLength={50} type="text" name="first_name" required value={intialvalues.first_name} className="border-dark w-75" placeholder="Enter First Name" />
                    <Form.Control.Feedback type="invalid">{validated && (intialvalues?.first_name?.length < 3 || intialvalues?.first_name?.length > 50) ? "length of input must be between 3 and 50" : "Please provide a   name."}</Form.Control.Feedback>
                  </Row>
                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <Form.Control onChange={handleChange} type={"text"} name="last_name" required maxLength={50} value={intialvalues.last_name} className="border-dark w-75" placeholder="Enter Last Name" />
                    <Form.Control.Feedback type="invalid">{validated && (intialvalues?.last_name?.length > 50 ? "length of input must be between 3 and 50" : "Please provide a valid  name.")}</Form.Control.Feedback>
                  </Row>

                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <ReactInputMask className="border-dark w-75" mask="+\92-399-9999999" value={intialvalues.phone_number} minLength={15} onChange={handleChange} name="phone_number" required>
                      <Form.Control isValid={validated && intialvalues?.phone_number?.length === 14} isInvalid={validated && intialvalues?.phone_number?.includes("_")} minLength={14} className="border-dark w-75" placeholder="xxxxxxxxxxx" type="tel" required={true} />
                    </ReactInputMask>
                    <Form.Control.Feedback type="invalid">{validated && !intialvalues?.phone_number?.includes("_") ? "Please provide a phone number." : "Enter a Valid Phone Number"}</Form.Control.Feedback>
                  </Row>

                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <Form.Control onChange={handleChange} required name="email" type="email" value={intialvalues.email} className="border-dark w-75" placeholder="Email" />
                    <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
                  </Row>

                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <Form.Control onChange={handleChange} name="password" type="password" required value={intialvalues.password} className="border-dark w-75" placeholder="Password" />
                    <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
                  </Row>

                  <Row lg={8} className={`pe-0 mt-3 text-center d-flex justify-content-center ${styles.cities}`}>
                    <Form.Control
                      onChange={(e: any) => {
                        setConfirmPassword(e.target.value);
                        if (intialvalues.password === e.target.value) {
                        }
                      }}
                      value={confirmPassword}
                      className="border-dark w-75"
                      type="password"
                      required
                      placeholder="Confirm Password"
                      isValid={validated && intialvalues.password === confirmPassword}
                      isInvalid={validated && intialvalues.password !== confirmPassword}
                    />
                  </Row>

                  {intialvalues.password !== confirmPassword ? <p className="text-danger text-center mb-0 mt-1">Passwords must match.</p> : ""}
                  <div className={` d-flex justify-content-center ms-2 text-center w-75`}>
                    <Button type="submit" className="w-75 mt-4 ">
                      {signupMutation.isLoading ? <ButtonLoader /> : "SignUp"}
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
            <div
              className={`${styles.signup_content__line_break}`}
              style={
                mode === "signup" ? { display: "none" } : { display: "block" }
              }
            >
              <div className={`${styles.divider} `}></div>
              <div className={`${styles.signup_content__or}`}>or</div>
            </div>
            <div className={` mb-3  d-flex justify-content-center `} style={mode === "signup" ? { display: "none" } : { display: "block" }}>
              {/* <div className={`${styles.divider} `}></div> */}
              {mode === "login" && <span
                onClick={() => {
                  setError(" ");
                  setValidated(false);
                  if (loginMethod === "Email") {
                    setLoginMethod("OTP")
                  } else {
                    setLoginMethod("Email")
                  }
                }}
                className={` cursor-pointer text-center link text-decoration-underline`}>{loginMethod === "OTP" ? "  Sign in with email" : " Sign in with OTP"} <FontAwesomeIcon className="cursor-pointer fs-16 link ms-1" icon={faArrowCircleRight} /> </span>
              } </div>
            <div className="d-flex justify-content-center ps-2">
              <Button
                onClick={() => {
                  navigate(pathname);
                  googleLogin(props.onHide());
                }}
                className="fs-14 py-3 mx-2  mb-3 mt-2  w-75 "
                variant="dark"
                size="lg"
                style={mode === "signup" ? { display: "none" } : { display: "block" }}
              >
                <img className="mx-2" height={23} width={23} src="https://www.svgrepo.com/download/448227/google.svg" />
                Continue with Google
              </Button>
            </div>
            {/* <Link onClick={() => router.push('/login')}  className='fs-14 w-100 py-3 btn btn-outline-light' href={`/login?redirect=${pathname}`}  size='lg' >Continue with Email</Link> */}

            <div className="mt-4 d-flex justify-content-center">
              <span>
                {" "}
                {mode === "login" ? "Don't have an account?" : "Already have an account?"}{" "}
                <span
                  className="link text-decoration-underline cursor-pointer"
                  onClick={() => {
                    setMode(mode === "signup" ? "login" : "signup");
                    setValidated(false);
                    setError("");
                  }}
                >
                  {mode === "login" ? "Create an Account" : "Login"}{" "}
                </span>
              </span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default SignInModal;
