"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./AdBanner.module.css";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Image from "next/image";
import { homeBanner1, homeBanner2 } from "../../../public/images";
import { useRouter } from "next/navigation";

const AdBanners = ({ Banners, isLoading }: any) => {
  const router = useRouter();

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={`${styles.mySwiper} w-100 h-100 px-1 mb-1 rounded-2  `}
      >
        {isLoading
          ? // Skeleton loading state
          [1, 2, 3].map((_, index) => (
            <SwiperSlide key={`skeleton-${index}`}>
              <div
                className={`${styles.skeleton_banner} w-100`}
                style={{ height: 200 }}
              />
            </SwiperSlide>
          ))
          : Banners?.length
            ? // Render banners if available
            Banners.map((banner, index) => (
              <div

                className={`${styles.swiperDiv}`}

              >
                <SwiperSlide
                  key={index}
                  onClick={() => {
                    router.push(banner?.link);
                  }}
                >
                  <Image
                    src={`${process.env.NEXT_PUBLIC_APP_URL_S3}/${banner?.img}`}
                    width={1500}
                    height={200}
                    className="w-100"
                    alt={`ad banner rounded-2   ${index + 1}`}
                  />
                </SwiperSlide>
              </div>
            ))
            : // Fallback images when no banners exist
            [homeBanner1, homeBanner2].map((fallbackImage, index) => (
              <SwiperSlide key={`fallback-${index}`}>
                <Image
                  src={fallbackImage}
                  width={1500}
                  height={200}
                  className="w-100"
                  alt={`fallback ad banner rounded-2  ${index + 1}`}
                />
              </SwiperSlide>
            ))}

        {/* {[1, 2, 3].map((map, index) => (
          <>
            <SwiperSlide key={index}>
              <Image
                key={index}
                src={homeBanner1}
                width={1500}
                height={200}
                className="w-100"
                alt="ad banner"
              />
            </SwiperSlide>
            <SwiperSlide key={index}>
              <Image
                key={index}
                src={homeBanner2}
                width={1500}
                height={200}
                className="w-100"
                alt="ad banner"
              />
            </SwiperSlide>
          </>
        ))} */}
        {/* skeleton image place holder with full witdth */}

        {/* Your ad banner image here */}
      </Swiper>
    </>
  );
};

export default AdBanners;
