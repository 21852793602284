import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/1. 700x300 copy 233.jpg");
;
import(/* webpackMode: "eager" */ "/app/public/images/1.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/1015 x200w.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/1015 x200w2.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/1500x200.2.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/1500x200.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/22.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/50percent.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/AboutUs_Banner.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/accessories.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/banner.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/BannerImage.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/brand-image.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/Desktop.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/desktopImage.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/exclusive.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/explore.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/headPhoneImage.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/laptop.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/laptop.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/laptops.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/mobileimage.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/Phone.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/phones.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/shop.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/storelogo.jpg");
;
import(/* webpackMode: "eager" */ "/app/public/images/tablet.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/tabletImage.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/tblogo.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/tbLogoName.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/TechBazaar.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/TV.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/tvv.webp");
;
import(/* webpackMode: "eager" */ "/app/public/images/ultrcodesLogo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/videoIcon.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/home-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ScreenLoader.tsx");
