"use client";
import { useState, useEffect } from "react";
import RecomendedProducts from "../sections/Home/RecomendedProducts";
import FeaturedCategories from "../sections/Home/FeaturedCategories";
import Brands from "../sections/Home/Brands";
import TopSellers from "../sections/Home/TopSellers";
import Banner from "../sections/Home/Banner";
import { useNavigationEvent } from "./hooks/useNavigationEvent";
import MostViewed from "../sections/Home/MostViewed";
import AdBanners from "../sections/Home/AdBanners";
import { useQuery } from "@tanstack/react-query";
import { getBannesByPage } from "./apis/getApis";
import Carousal from "../components/Themes/Carousal";
import ShopByPrice from "../sections/Home/ShopByPrice";

export default function HomePage() {
  const [banners, setBanners] = useState([]);
  // useNavigationEvent();
  // const [domLoaded, setDomLoaded] = useState(false);
  // useEffect(() => {
  //   setDomLoaded(true);
  // }, []);

  const getBanners = useQuery({
    queryKey: ["home-banners"],
    queryFn: () => getBannesByPage("home"),
    refetchOnWindowFocus: true,
    cacheTime: 0,
    onSuccess: (data) => {
      // console.log(data?.data?.body?.data, 'data');
      setBanners(data?.data?.body?.data);
    },
  });

  // console.log(banners, "banners");

  return (
    <>
      <main>
        <Banner Banners={banners} isLoading={getBanners.isLoading} />
        <FeaturedCategories />
        <RecomendedProducts />
        <ShopByPrice />
        <AdBanners
          Banners={banners?.carousal_1}
          isLoading={getBanners.isLoading}
        />
        <TopSellers />
        <MostViewed />
        <AdBanners
          Banners={banners?.carousal_2}
          isLoading={getBanners.isLoading}
        />
        <Brands />
      </main>
    </>
  );
}
