"use client";
import Image from "next/image";
import Link from "next/link";
import { Button, Col, Form, Row } from "react-bootstrap";
import styles from "./../styles/Footer.module.css";
import {
  TechBazaarImage,
  tblogoName,
  ultracodesLogo,
} from "../../public/images";
import { facebook, instagram, youtube, linkedin } from "../../public/icons";
import { useRouter } from "next/navigation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import React from "react";
import { fetchOrderByOrderNo } from "../app/apis/getApis";

const Footer = () => {
  const router = useRouter();
  const [TrackingID, setTrackingId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["viewList"]);

  const fetchOrder = useMutation({
    mutationKey: ["order", TrackingID],

    mutationFn: () => fetchOrderByOrderNo(TrackingID),
    onSuccess: () => {
      router.push(`/Orders/${TrackingID}`);
      setTrackingId("");
      setError("");

      // return data?.data?.body?.data;
    },
    onError: (error: any) => {
      setError("Invalid Order ID");
    },
  });

  return (
    <footer className="main  ">
      <Row
        className={`${styles.footerRow} p-2 ms-1 ms-lg-0 ms-md-0 align-items-start h-100 pt-lg-3 pt-md-2 mt-lg-4 mt-md-4 px-3 `}
      >
        <Col
          xs="12"
          sm="5"
          md="4"
          lg="5"
          xl="6"
          className={`px-0 ps-0 ps-lg-0  pt-lg-0 pt-md-2 pt-4 d-flex flex-md-column flex-column align-items-start justify-content-around  align-items-md-start justify-content-md-center ${styles.footer_row}`}
        >
          <Image
            className="img-fluid"
            src={tblogoName}
            alt="logo"
            width={110}
            height={30}
          />

          <p>
            On a mission to induce digitization and data driven business growth
            in the tech retail industry, by providing a one stop solution in the
            form a POS connected Marketplace.
          </p>

          <h6 className="pb-lg-0 mb-0 "> Join TechBazaar! </h6>
          <Link href="/become-seller">
            <Button
              className={`${styles.sellerBtn} p-2 rounded-2 my-3`}
              variant="outline-primary"
            >
              Become a seller
            </Button>
          </Link>
        </Col>
        <Col
          xs="12"
          sm="4"
          md="4"
          lg="4"
          xl="3"
          className="px-0 pt-2 pt-lg-0  d-grid  justify-content-start justify-content-lg-center justify-content-md-center justify-content-xl-center"
        >
          <h6 className="pb-lg-0">TechBazaar</h6>
          <ul className={`${styles.footerLinks}`}>
            <li>
              <Link href={"/about-us"} as="/about-us" shallow>
                About Us
              </Link>
            </li>
            <li>
              <Link href={"/store"} as="/store">
                Trusted Retailers
              </Link>
            </li>
            <li>
              <Link href={"/products"} as="/products">
                Products
              </Link>
            </li>
            <li>
              <Link href={"/specs"} as="/specs">
                Specs Central
              </Link>
            </li>

            <li>
              <a
                href={"https://blog.techbazaar.pk"}
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
            <li>
              <p
                className="fs-16 mb-0 cursor-pointer"
                onClick={() => {
                  window.location.href = "mailto:help@techbazaar.pk";
                }}
              >
                Contact us
              </p>
            </li>
            <li>
              <Link href={"/sitemap.xml"} as="/sitemap.xml">
                Sitemap
              </Link>
            </li>
            <li>
              <Link href={"/privacy-policy"} as="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </Col>
        <Col md="12" className="px-0 d-block d-md-none d-lg-none">
          <hr className={`w-100 my-4 ${styles.separator}`} />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="px-0">
          <h2 className="fs-18"> Follow Us!</h2>
          <div className="d-flex gap-2 mt-2">
            <Image
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/techbazaar.com.pk?mibextid=LQQJ4d",
                  "_blank"
                );
              }}
              src={facebook}
              alt="facebook"
              width={22}
              height={23}
            />
            <Image
              onClick={() => {
                window.open(
                  "https://www.youtube.com/@TechBazaar-bl5nl",
                  "_blank"
                );
              }}
              className="cursor-pointer"
              src={youtube}
              alt="youtube"
              width={22}
              height={23}
            />
            <Image
              onClick={() => {
                window.open(
                  "https://www.instagram.com/techbazaar.official",
                  "_blank"
                );
              }}
              className="cursor-pointer"
              src={instagram}
              alt="instagram"
              width={22}
              height={23}
            />
            <Image
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/techbazaar/",
                  "_blank"
                );
              }}
              className="cursor-pointer"
              src={linkedin}
              alt="instagram"
              width={22}
              height={23}
            />
          </div>
          <Form
            className="d-flex mt-2 flex-column"
            onSubmit={(e) => {
              e.preventDefault();
              fetchOrder.mutate();
            }}
          >
            <div className="d-flex">
              <Form.Control
                value={TrackingID}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\s/g, ""); // Remove spaces
                  setTrackingId(newValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault(); // Prevent space input
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault(); // Prevent pasting whitespace
                  const pastedText = e.clipboardData.getData("text");
                  const sanitizedText = pastedText.replace(/\s/g, ""); // Remove spaces from pasted content
                  setTrackingId((prev) => prev + sanitizedText);
                }}
                placeholder="Enter tracking ID"
                isInvalid={!!error}
              />

              <Button
                type="submit"
                className="text-nowrap "
                variant="outline-primary"
              >
                Track Order
              </Button>
            </div>
            {error && <p className="text-danger">{error}</p>}
          </Form>
        </Col>
      </Row>
      <div>
        <hr className={`w-100 my-4 ${styles.separator}`} />
      </div>
      <p className="text-center fs-12">
        © {new Date().getFullYear()} Techbazaar. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
