"use client";

import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../styles/TopSeller.module.css";
import Slider from "react-slick";
import tick from "../../../public/icons/tickk.svg";
import exclusive from "../../../public/icons/exclusive.svg";
import discountt from "../../../public/icons/discount.svg";
import popular from "../../../public/icons/popular.svg";

import { apple, samsung, oneplus, storeLogo, shopiconn } from "../../../public/images";
import Image from "next/image";
import ScrollDown from "../../components/ScrollDown";
import Link from "next/link";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchRecommendedListing, fetchTopSeller, getTopdiscountedListings } from "../../app/apis/getApis";
import { useState } from "react";
import { marketplaceCdn } from "../../utility/env";
import { store_visit } from "../../UserEvents/UserEvents";
import { useUserStore } from "../../store/User";
import { LoadingCard } from "../../components";
import ProductCardLoading from "../SpecDetails/ProductCardLoading";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Mousewheel, Navigation, Pagination, Virtual } from 'swiper/modules';
import { useRouter } from "next/navigation";
import { replaceSpecialCharacters } from "../../utility/Images/utils";



interface seller {
  logo_path: string
  shop_name: string
  shop_id: number
  username: string

}

const ImageContainer = ({ name, url }: any) => {
  const [hasimg, setHasimg] = useState(url ? true : false);



  return (
    <div className={`${styles.sellerSlider} `}>
      {hasimg ? (
        <Image
          src={`${marketplaceCdn}/${url}`}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      ) : (
        <Image
          src={storeLogo}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      )}
    </div>
  );
};

const TopSellers = () => {
  const [data, setData] = useState([]);
  const { user, token } = useUserStore((state: any) => state);
  const { location } = useUserStore((state: any) => state)
  const router = useRouter()

  const queryClient = useQueryClient()

  const items = useQuery({
    queryKey: ["best-product", location ? location : ""],
    queryFn: () => fetchRecommendedListing(10, 0, location),
    refetchOnWindowFocus: true,
    cacheTime: Infinity,
    onSuccess: (data) => {

    },
  });

  const topSellerQuery = useQuery({
    queryKey: ["topSeller"],
    queryFn: () => fetchTopSeller(),
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setData(data.data.body.data);
    },
  });


  const topDicounted = useQuery({
    queryKey: ["topDiscounted", [location]],
    queryFn: () => getTopdiscountedListings(location ? location : ""),
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.log(data.data.body.data);
    },
  });

  return (
    <div className={` ${styles.topSellers}`}>
      <main className="  main ">
        <div className={`row mx-2  gap-3 p-0`}>

          <div className={`  col-lg-3 col-12 ${styles.top_seller_div} ${topSellerQuery?.data?.data?.body?.data.length <= 3 ? styles.top_Sellerheight : ""}  `} >
            <p className="fw-500 text-center fs-18 " >
              <Image style={{ mixBlendMode: "darken" }} src={shopiconn} alt="logo" width={30} height={30} className="me-2" />
              Explore Stores

            </p>
            <Swiper

              direction={'vertical'}
              // slidesPerView={topSellerQuery?.data?.data?.body?.data.length <= 3 ? 2 : 4}
              mousewheel={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}

              modules={[Virtual, Navigation, Pagination, Mousewheel, Autoplay]}
              breakpoints={
                {
                  575: {
                    slidesPerView: 2,
                  },
                  640: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }
              }
              navigation={false}

              className="mySwiper w-100  "
            >
              {topSellerQuery.isLoading
                ?
                <div className="d-flex flex-column flex-wrap justify-content-center w-100 mb-2 " >
                  {[1, 2, 3,].map((item) => (
                    <CardLoading />
                  ))}
                </div>

                : topSellerQuery?.data?.data?.body?.data?.map((seller, index) => (
                  <SwiperSlide className="justify-content-center " key={index}>
                    <Link href={`/store/${seller.username}`} onClick={() => store_visit(seller?.shop_id, user?.uuid, 'Top Seller', `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}store/${seller.username}`, seller?.shop_name)}>
                      <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2`}>
                        <div className={`${styles.seller_logo} rounded-circle border border-1`}>
                          <Image
                            src={seller.logo_path ? `${process.env.NEXT_PUBLIC_APP_URL_S3}/${seller.logo_path}` : storeLogo}
                            width={64}
                            height={64}
                            className="h-100 p-1 rounded-circle w-100"
                            alt={`${seller.shop_name} logo`}
                          />
                        </div>
                        <div className="seller-name align-content-center">
                          <p className="fw-500 fs-14 h-100 align-middle d-inline">
                            {seller.shop_name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}



            </Swiper>

          </div>
          <div style={{ background: "aliceblue" }} className={`col   ${styles.best_sellers}`} >
            <p className="fw-500 fs-18 mb-2 top-p  text-center" >
              <Image style={{ mixBlendMode: "darken" }} src={discountt} alt="logo" width={40} height={40} className="me-2 " />
              Top Discounted

            </p>
            <div className=" d-lg-flex d-md-flex d-flex flex-column flex-lg-row d-sm-flex gap-2   ">
              <div className="w-100  d-flex flex-column" > {
                topDicounted?.data?.data?.body?.data.slice(0, 5).map((item, index) => (

                  <div
                    style={{ height: "80px" }}

                    onClick={() => {
                      router.push(`/products/${replaceSpecialCharacters(item.listing_title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${item.listing_id}`);
                    }}
                    className="d-flex cursor-pointer position-relative mt-1  border-bottom bg-white rounded-3 shadow-sm mb-1  ">
                    {item.discount_percentage > 0 && item?.effective_price > 0 && (
                      <div
                        className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.discount}`}
                        style={{
                          // borderTopLeftRadius: "7px",
                          borderBottomLeftRadius: "7px",
                          // borderBottomRightRadius: "7px",
                          right: "0",
                        }}
                      >
                        <span
                          className="m-0 py-1 text-white fs-12 fw-bold p-2"
                          style={{ fontSize: "11px !important", cursor: "pointer" }}
                        >
                          {Number(item.discount_percentage)?.toFixed()}% OFF
                        </span>
                      </div>
                    )}
                    <div className="h-100 object-fit-contain ">

                      <Image
                        height={84}
                        width={94}
                        className={`rounded-2  ${styles.recomCard} mx-2  `}
                        alt="productimg"
                        src={`${marketplaceCdn}/${item?.item?.itemImages?.img0}`}
                      />
                    </div>
                    <div className="d-flex flex-column ">

                      <p className={`fs-16 fw-500 mb-2 overflow-hidden ${styles.title}`}> {item.listing_title} </p>

                      <span
                        className={`  ${styles.condition_container}   text-white   ${item.item.condition_item === "Used" ? "used" : item.item.condition_item === "New" ? "new" : "refurbished"}   me-1  py-1 text-center fs-12`}>
                        {item.item.condition_item}
                      </span>
                      <p className={"fs-14 fw-500 mb-0  d-flex gap-2 "}> Rs  {item?.effective_price}
                        <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {item.online_price}</p>
                      </p>
                    </div>
                  </div>
                )
                )}</div>
              {topDicounted?.data?.data?.body?.data.length > 5 && <div className="w-100 d-block d-lg-block ">   {
                topDicounted?.data?.data?.body?.data.slice(6, 11).map((item, index) => (

                  <div
                    style={{ height: "80px" }}

                    onClick={() => {
                      router.push(`/products/${replaceSpecialCharacters(item.listing_title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${item.listing_id}`);
                    }}
                    className="d-flex cursor-pointer position-relative mt-1  border-bottom bg-white rounded-3 shadow-sm  mb-2">
                    {item.discount_percentage > 0 && item?.effective_price > 0 && (
                      <div
                        className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.discount}`}
                        style={{
                          // borderTopLeftRadius: "7px",
                          borderBottomLeftRadius: "7px",
                          // borderBottomRightRadius: "7px",
                          right: "0",
                        }}
                      >
                        <span
                          className="m-0 py-1 text-white fs-12 fw-bold p-2"
                          style={{ fontSize: "11px !important", cursor: "pointer" }}
                        >
                          {Number(item.discount_percentage)?.toFixed()}% OFF
                        </span>
                      </div>
                    )}
                    <div className="h-100 object-fit-contain ">

                      <Image
                        height={84}
                        width={94}
                        className={`rounded-2  ${styles.recomCard} mx-2  `}
                        alt="productimg"
                        src={`${marketplaceCdn}/${item?.item?.itemImages?.img0}`}
                      />
                    </div>
                    <div className="d-flex flex-column  ">

                      <p className={`fs-16 fw-500 mb-2 overflow-hidden ${styles.title}`}> {item.listing_title} </p>

                      <span
                        className={`  ${styles.condition_container}   text-white   ${item.item.condition_item === "Used" ? "used" : item.item.condition_item === "New" ? "new" : "refurbished"}   me-1  py-1 text-center fs-12`}>
                        {item.item.condition_item}
                      </span>
                      <p className={"fs-14 fw-500 mb-0  d-flex gap-2 "}> Rs  {item?.effective_price}
                        <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {item.online_price}</p>
                      </p>
                    </div>
                  </div>
                )
                )}
              </div>}
            </div>
          </div>

        </div>

      </main >
    </div >


  );
};

export default TopSellers;



const CardLoading = () => {

  return (

    <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2 ${styles.skeleton} w-100 mb-2  `}>
      <div className={`${styles.seller_logo} rounded-circle border border-1 ${styles.skeleton}`} />
      <div className="seller-name align-content-center">
        <p className={`fw-500 fs-14 h-100 align-middle d-inline ${styles.skeleton} ${styles.skeleton_text}`} />
      </div>
    </div>

  )
}

const RecomendedCard = ({ image, title, price, salePrice, discount_percentage }: any) => {
  const router = useRouter();
  return (
    <div
      onClick={() => {
        // router.push("/specs");
      }}
      className={` ${styles.recomendedCard} position-relative d-flex gap-2 mb-2 rounded-3  px-1 bg-white w-100 border  align-items-center cursor-pointer box-shadow`}
    >
      {/* {price > 0 && (
        <div
          className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.featured}`}
          style={{
            // borderTopLeftRadius: "7px",
            borderBottomLeftRadius: "7px",
            // borderBottomRightRadius: "7px",
            right: "0"
          }}
        >
          <p
            className="m-0 py-0 text-white fw-bold p-2"
            style={{ fontSize: "11px", cursor: "pointer", }}
          >
            {Number(discount_percentage)?.toFixed()}% OFF
          </p>
        </div>
      )} */}

      <Image
        height={74}
        width={74}
        className={`rounded-2  ${styles.recomCard} `}
        alt="productimg"
        src={`${marketplaceCdn}/${image}`}
      />

      <p className={"fs-14 fw-500 mb-0   "}> Rs  {price}
        {salePrice > 0 && <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {salePrice}</p>}
      </p>


    </div>
  );
};