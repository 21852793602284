"use client";

import { Tab, Tabs } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";

import styles from "../../styles/Brands.module.css";
import Image from "next/image";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { GetBrands } from "../../app/apis/getApis";
import { LoadingCard } from "../../components";
import Link from "next/link";
import { brandPlacehoder } from "../../../public/images";
import { sendGTMEvent } from "@next/third-parties/google";

interface Brand {
  category: string
  category_id: string

  brands: Array<{
    id: string
    label: string
    logo: string
  }>


}
const Brands = () => {
  const [triger, setTriger] = useState(false);
  const [data, setData] = useState<Brand[]>([]);;
  const fetchBrand = useQuery({
    queryKey: ["Brands"],
    queryFn: () => GetBrands(),
    // enabled: !!SeeMoreModal,
    // refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (data) => {
      let arrayOfObjects: Brand[] = Object.values(data?.data?.body?.data);
      setData(arrayOfObjects);
    },
  });
  useEffect(() => { }, [triger]);
  const createParams = (filters: any[]) => {
    let paramss = new URLSearchParams();
    filters.forEach((filter: [string, any]) => {
      paramss.append(...filter);
    });
    return `${(paramss.toString())}`;
  };

  return (
    <div className={`${styles.TopBrandsDiv} `}>
      <main className="main">
        <h1 className="text-center">Top Brands</h1>

        {fetchBrand.isLoading ? (
          <div className="d-flex flex-nowrap justify-content-center  " style={{ columnGap: '10px' }}>
            {[1, 2, 3, 4].map((item) => (
              <CardLoading />))}
          </div>
        ) : (
          <div className={`${styles.tabsDiv}`}>
            <Tabs
              onClick={() => {
                setTriger((prev) => !prev);
              }}
              defaultActiveKey="Laptop"
              id="uncontrolled-tab-example"
              className=""
            >
              {data?.map((data) => (
                <Tab eventKey={data.category} title={data?.category}>
                  <motion.div
                    initial={{ y: 0, opacity: 0 }}
                    whileInView={{ y: 10, opacity: 1 }}
                    transition={{ type: "tween", duration: 0.5 }}
                    className={`${styles.grid}`}
                  >
                    {data?.brands.map((brand, index) => (
                      <Link
                        onClick={() => {
                          sendGTMEvent({
                            event: 'brand_click',
                            value: brand.label,
                            url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}products?${createParams([
                              ["brand_ids", `[${brand.id}]`],
                              ["category_id", data.category_id],
                            ])}`
                          })
                        }}
                        href={`/products?${createParams([
                          ["brand_ids", `[${brand.id}]`],
                          ["category_id", data.category_id],
                        ])}`}

                      >
                        <div
                          key={index}
                          className={`${styles.gridItems} text-center`}
                        >
                          <Image
                            src={brand.logo ? `${process.env.NEXT_PUBLIC_APP_URL_S3}/${brand?.logo}` : brandPlacehoder.src}
                            alt={brand?.label}
                            width={65}
                            height={64}

                          />
                          <h5 className="text-center my-2">{brand?.label}</h5>
                        </div>
                      </Link>
                    ))}
                  </motion.div>
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
      </main>
    </div>
  );
};

export default Brands;


const CardLoading = () => {

  return (

    <div className={`  text-center ${styles.skeleton_grid_item} `} style={{ width: '260px', height: '200px' }}>

      <div className={`${styles.skeleton} ${styles.skeleton_image} `} />
      <h5 className={`text-center my-2 ${styles.skeleton} ${styles.skeleton_text}`} />
    </div>


  )
}
